import React, {useEffect, useRef, useState} from 'react';
import {Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {addDoc, collection, doc, onSnapshot, setDoc} from "firebase/firestore";
import {db, storage} from "../../../firebase";
import {useSnackbar} from "notistack";
import DragTable from "./DragTable";
import Typography from "@mui/material/Typography";
import CloudUploadTwoToneIcon from "@mui/icons-material/CloudUploadTwoTone";
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import {getDownloadURL, ref, uploadBytesResumable} from "firebase/storage";
import {v4} from "uuid";
import 'moment-timezone';


const Schedule = (props) => {
    const {rowData, scheduleData} = props;
    const {enqueueSnackbar} = useSnackbar();


    const [loading, setLoading] = React.useState(false);
    const [companyName, setCompanyName] = useState(rowData ? rowData.companyName : "");
    const [branchName, setBranchName] = useState(rowData ? rowData.branchName : "");
    const [collectionName, setCollectionName] = useState(rowData ? rowData.collectionName : "");
    const [scheduleName, setScheduleName] = useState(scheduleData? scheduleData.scheduleName:"");
    const [collectionList, setCollectionList] = useState([]);
    const [allCompanyList, setAllCompanyList] = useState([]);
    const [allBranchList, setAllBranchList] = useState([]);
    const [allCollectionList, setAllCollectionList] = useState([]);
    const [scheduleDateTime, setScheduleDateTime] = useState(scheduleData ? scheduleData.scheduleDateTime.toDate():new Date());
    const [fileName, setFileName] = useState(scheduleData?scheduleData.fileName:"");
    const [selectAds, setSelectAds] = useState(scheduleData?scheduleData.selectAds:[]);
    const fileRef = useRef();
    const [file, setFile] = useState("");
    const [fileType, setFileType] = useState("");

    const submitDetails = async () => {
        setLoading(true);

        console.log("Collection ID :",  rowData.id,)
        console.log("rowData.timezone :",  rowData.timezone)

        //Date Convert
        const subDate = new Date((typeof scheduleDateTime === "string" ? new Date(scheduleDateTime) : scheduleDateTime).toLocaleString("en-US", {timeZone: rowData.timezone}));
        console.log("Submit Time :",  subDate)

        let totalDue = 0;
        await selectAds.forEach((arrayItem) => {
            totalDue += arrayItem.duration;
        })

        //validations
        if (selectAds.length === 0) {
            enqueueSnackbar("Table empty!", {variant: "error"});
        } else if (selectAds.some((value, index) => isNaN(value.duration))) {
            enqueueSnackbar("Some duration time empty!", {variant: "error"});
        } else if (selectAds.some((value, index) => value.duration < 1)) {
            enqueueSnackbar("Some duration time invalid!", {variant: "error"});
        } else if (scheduleName.match(/^ *$/) !== null) {
            enqueueSnackbar("Schedule name empty!", {variant: "error"});
        } else if (scheduleDateTime === null) {
            enqueueSnackbar("Schedule time empty!", {variant: "error"});
        } else if (isNaN(scheduleDateTime)) {
            enqueueSnackbar("Schedule time invalid!", {variant: "error"});
        } else if (file !== "" && fileType !== "audio") {
            enqueueSnackbar("Invalid file type!", {variant: "error"});
        } else if(file !== "") {

            //set order
            selectAds.map((value, index) => value.order = index)
            console.log("Value :", selectAds)

            //calculate total duration

            console.log("Total Time :", totalDue)

            if (file !== ""){
                const storageRef = ref(storage, file.name + v4());
                const uploadTask = uploadBytesResumable(storageRef, file);

                uploadTask.on(
                    "state_changed",
                    (snapshot) => {
                        const progress =
                            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        console.log("Upload is " + progress + "% done");
                        setLoading(true);
                        switch (snapshot.state) {
                            case "paused":
                                console.log("Upload is paused");
                                break;
                            case "running":
                                console.log("Upload is running");
                                break;
                            default:
                                break;
                        }
                    },
                    (error) => {
                        console.log(error);
                    },
                    () => {
                        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                            const payload = {
                                companyName,
                                branchName,
                                collectionName,
                                collectionId: rowData.id,
                                scheduleName,
                                selectAds,
                                audioFile: downloadURL,
                                duration: totalDue,
                                scheduleDateTime:subDate,
                                fileName
                            };
                            if (scheduleData){
                                const docRef = doc(db, "schedule", scheduleData.id)
                                await setDoc(docRef, payload).then(() => {
                                    enqueueSnackbar("Updated the schedule!", {variant:"success"});
                                    setScheduleName("")
                                    setLoading(false);
                                })

                            }else {
                                const colRef = collection(db, "schedule");

                                const docRef = await addDoc(colRef, payload).then(() => {
                                    enqueueSnackbar("Created the schedule!", {variant: "success"});
                                    setScheduleName("")
                                    // setScheduleDateTime(new Date())
                                    setFile("")
                                    setSelectAds([])
                                    setLoading(false);
                                })
                            }
                        });
                    }
                );
            }
        } else {
            if (scheduleData){
                const payload = {
                    companyName,
                    branchName,
                    collectionName,
                    collectionId: rowData.id,
                    scheduleName,
                    selectAds,
                    audioFile: scheduleData.audioFile,
                    duration: totalDue,
                    scheduleDateTime:subDate,
                    fileName:scheduleData.fileName
                };

                const docRef = doc(db, "schedule", scheduleData.id)
                await setDoc(docRef, payload).then(() => {
                    setLoading(false);
                })


            } else {
                const payload = {
                    companyName,
                    branchName,
                    collectionName,
                    collectionId: rowData.id,
                    scheduleName,
                    selectAds,
                    audioFile: "",
                    duration: totalDue,
                    scheduleDateTime:subDate,
                    fileName
                };
                const colRef = collection(db, "schedule");

                const docRef = await addDoc(colRef, payload).then(() => {
                    enqueueSnackbar("Created the schedule!", {variant: "success"});
                    setScheduleName("")
                    // setScheduleDateTime(new Date())
                    setFile("")
                    setSelectAds([])
                    setLoading(false);
                })
            }
        }


        setLoading(false);

    }
    const handleChange = (newValue: Date | null) => {
        setScheduleDateTime(newValue);
    };

    useEffect(() => {
        if (!rowData) {
            let company = collectionList.filter((ele, ind) => ind === collectionList.findIndex(elem => elem.companyName === ele.companyName))
            setAllCompanyList(company);
            let branch = collectionList.filter((ele, ind) => ind === collectionList.findIndex(elem => elem.branchName === ele.branchName))
            setAllBranchList(branch);
            let collection = collectionList.filter((ele, ind) => ind === collectionList.findIndex(elem => elem.collectionName === ele.collectionName))
            setCollectionName(collection);
        } else {
            let company = collectionList.filter((ele, ind) => ind === collectionList.findIndex(elem => elem.companyName === ele.companyName && elem.branchName === rowData.branchName && elem.collectionName === rowData.collectionName))
            console.log("Company : ", company)
            setAllCompanyList(company);
            let branch = collectionList.filter((ele, ind) => ind === collectionList.findIndex(elem => elem.branchName === ele.branchName && elem.companyName === rowData.companyName && elem.collectionName === rowData.collectionName))
            console.log("branch : ", branch)
            setAllBranchList(branch);
            let collection = collectionList.filter((ele, ind) => ind === collectionList.findIndex(elem => elem.collectionName === ele.collectionName && elem.companyName === rowData.companyName && elem.branchName === rowData.branchName))
            console.log("collection : ", collection)
            setAllCollectionList(collection);
        }
    }, [collectionList]);

    useEffect(() => {
        const comRef = collection(db, "company");
        const colRef = collection(db, "collection");
        onSnapshot(
            colRef,
            (snapShot) => {
                let list = [];
                snapShot.docs.forEach((doc) => {
                    list.push({id: doc.id, ...doc.data()});
                });
                setCollectionList(list);
                console.log("-----set Company List---- ");
            },
            (error) => {
                console.log(error);
            }
        )

    }, []);

    useEffect(() => {
        console.log("UseEffect Time : ", scheduleDateTime);

    }, [scheduleDateTime]);

    const fileUpload = (e) => {
        const uploadFile = e.target.files[0];
        setFile(uploadFile);
        setFileName(uploadFile.name)
        setFileType(uploadFile.type.split("/")[0]);

    }

    return (
        <Box sx={{borderRadius: 0, justifyContent: "center", alignItems: "center", display: "flex"}}>
            <Grid container justifyContent="space-around"
                  item lg={9} md={10} sm={12} xs={12} direction={"column"}
                  sx={{
                      // background: "#FBFBFB",
                      borderRadius: "10px",
                      padding: "50px",
                      marginBottom: "50px"
                  }}>
                <form action="#" method="post">

                    <Grid container direction={"row"} justifyContent="space-between">
                        {/* ------------------Company Name--------------------- */}
                        <Grid item sx={{paddingTop: "20px"}}>
                            <FormControl margin="dense" fullWidth>
                                <InputLabel id="demo-simple-select-label">Company</InputLabel>
                                <Select sx={{width: "100%", minWidth: "200px", borderRadius: "10px"}} variant="outlined"
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        disabled
                                        value={companyName}
                                        label="Company"
                                        onChange={(e) => {
                                            setCompanyName(e.target.value);
                                            setAllBranchList(collectionList.filter((ele, ind) => ind === collectionList.findIndex(elem => elem.branchName === ele.branchName && elem.companyName === e.target.value)));
                                            setCollectionList(collectionList.filter((ele, ind) => ind === collectionList.findIndex(elem => elem.collectionName === ele.collectionName && elem.companyName === e.target.value)));
                                        }}
                                >
                                    {allCompanyList.map((value) =>
                                        <MenuItem key={value.id}
                                                  value={value.companyName}>{value.companyName}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>

                        {/* -----------------------Branch Name---------------------------- */}

                        <Grid item sx={{paddingTop: "20px"}}>
                            <FormControl margin="dense" fullWidth>
                                <InputLabel id="demo-simple-select-label">Branch</InputLabel>
                                <Select sx={{width: "100%", minWidth: "200px", borderRadius: "10px"}} variant="outlined"
                                        value={branchName} label="Branch"
                                        disabled
                                        onChange={(e) => {
                                            setBranchName(e.target.value);
                                            setAllCompanyList(collectionList.filter((ele, ind) => ind === collectionList.findIndex(elem => elem.companyName === ele.companyName && elem.branchName === e.target.value)));
                                            setAllCollectionList(collectionList.filter((ele, ind) => ind === collectionList.findIndex(elem => elem.collectionName === ele.collectionName && elem.branchName === e.target.value)));
                                        }}
                                >
                                    {allBranchList.map((value) =>
                                        <MenuItem key={value.id} value={value.branchName}>{value.branchName}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>

                        {/* -----------------------Collection Name---------------------------- */}

                        <Grid item sx={{paddingTop: "20px"}}>
                            <FormControl margin="dense" fullWidth>
                                <InputLabel id="demo-simple-select-label">Collection</InputLabel>
                                <Select sx={{width: "100%", minWidth: "200px", borderRadius: "10px"}} variant="outlined"
                                        value={collectionName} label="Collection"
                                        disabled
                                        onChange={(e) => {
                                            setCollectionName(e.target.value);
                                            setAllCompanyList(collectionList.filter((ele, ind) => ind === collectionList.findIndex(elem => elem.companyName === ele.companyName && elem.collectionName === e.target.value)));
                                            setAllBranchList(collectionList.filter((ele, ind) => ind === collectionList.findIndex(elem => elem.branchName === ele.branchName && elem.collectionName === e.target.value)));
                                        }}
                                >
                                    {allCollectionList.map((value) =>
                                        <MenuItem key={value.id}
                                                  value={value.collectionName}>{value.collectionName}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>

                        {/* -----------------------Orientation---------------------------- */}

                        <Grid item sx={{paddingTop: "20px"}}>
                            <FormControl margin="dense" fullWidth>
                                <InputLabel id="demo-simple-select-label">Orientation</InputLabel>
                                <Select sx={{width: "100%", minWidth: "200px", borderRadius: "10px"}} variant="outlined"
                                        value={rowData.orientation} label="Orientation"
                                        disabled
                                >
                                    {allCollectionList.map((value) =>
                                        <MenuItem key={value.id}
                                                  value={value.orientation}>{value.orientation}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>

                    </Grid>

                    {/* -----------------------Table---------------------------- */}
                    <DragTable selectAds={selectAds} setSelectAds={setSelectAds} rowData={rowData}/>

                    {/* -----------------------Schedule Name---------------------------- */}

                    <Grid container item direction={"row"} justifyContent="space-between">
                        <Grid item sx={{paddingTop: "20px"}} xs={5.5}>
                            <TextField margin="dense" id="outlined-basic" fullWidth
                                // sx={{width: "100%", minWidth: "150px"}}
                                       variant="outlined" label={"Schedule Name"}
                                       value={scheduleName}
                                       onInput={(e) => setScheduleName(e.target.value)}/>
                        </Grid>
                        {/* -----------------------Date Time---------------------------- */}
                        <Grid item sx={{paddingTop: "20px"}} xs={5.5}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DateTimePicker
                                    label="Date&Time picker"
                                    value={scheduleDateTime}
                                    onChange={handleChange}
                                    renderInput={(params) => <TextField margin="dense" fullWidth {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>

                    {/* -----------------------File---------------------------- */}

                    <Grid container item direction={"column"}>
                        <Grid item direction={"column"} xs={12} sx={{paddingTop: "20px"}}
                              container
                              justifyContent="center">
                            <Button
                                variant={"outlined"}
                                component="label"
                                color={"inherit"}
                                sx={{
                                    borderRadius: "10px", borderColor: "#C4C4C4", "&:hover": {
                                        borderColor: "#303030",
                                    }
                                }}
                            >
                                <Grid item container direction={"column"} justifyContent="center"
                                      alignItems={"center"} p={10}>
                                    {scheduleData && !file && <Typography variant={"body2"}
                                                                      align={"center"}>{fileName ? fileName : ""}</Typography>}
                                    <Typography variant={"body2"}
                                                align={"center"}>{file ? file.name : ""}</Typography>
                                    <CloudUploadTwoToneIcon/>
                                    <Typography variant={"button"}>MP3 File</Typography>
                                    <input
                                        ref={fileRef}
                                        type="file"
                                        hidden
                                        onChange={(e) => fileUpload(e)}
                                    />
                                </Grid>
                            </Button>
                        </Grid>
                    </Grid>


                    <Grid sx={{paddingTop: "30px"}}>
                        <LoadingButton loading={loading} variant={"contained"} color={"primary"}
                                       sx={{width: "100%", height: "50px", borderRadius: "10px"}}
                                       onClick={() => submitDetails()}>{scheduleData ? "Update Schedule":"Create Schedule"}</LoadingButton>
                    </Grid>
                </form>
            </Grid>

        </Box>
    );
};

export default Schedule;
