import React, {useState} from "react";
import Login from "../../views/auth/Login";

const Auth = () => {
    const [index, setIndex] = useState(false);
    const toggleIndex = () => {
        setIndex((prevState) => !prevState);
    };
    return (
        // <div className="container">
        //     {!index ? <Login /> : <Register />}
        //     <p onClick={toggleIndex}>
        //         {!index ? "New user? Click here " : "Already have an acount?"}
        //     </p>
        // </div>
        <Login />
    );
};

export default Auth;
