import React, {useEffect} from 'react';
import {Checkbox, Grid, ImageList, ImageListItem, ImageListItemBar, ListItemButton, ListItemIcon} from "@mui/material";
import Box from "@mui/material/Box";
import {db} from "../../../firebase";
import {collection, onSnapshot, query, where,} from "firebase/firestore";
import {v4} from "uuid";
import CustomIndicator from "../../../components/CustomIndicator";

const SelectionView = (props) => {
    const {checked, setChecked, rowData} = props;

    const [adsList, setAdsList] = React.useState([]);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            value["scheduleItemId"] = v4();
            console.log("currentIndex :", value)
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    useEffect(() => {
        console.log("Selected List : ", checked)
    }, [checked]);

    useEffect(() => {
        const adsRef = collection(db, "advertisement");
        const adsQuery = query(adsRef, where("orientation", "==", rowData.orientation));
        onSnapshot(
            adsQuery,
            (snapShot) => {
                let list = [];
                snapShot.docs.forEach((doc) => {
                    list.push({id: doc.id, ...doc.data()});
                });
                setAdsList(list);
                console.log("-----set Company List---- ");
            },
            (error) => {
                console.log(error);
            }
        )
    }, [])
    return (
        <Box sx={{
            width: "auto",
            height: "auto",
        }}>
            {
                adsList.length !== 0 ?
                    <Grid container direction={"column"} flexDirection={"row"} justifyContent={"flex-start"}
                          alignItems={"center"} spacing={3}>

                        <ImageList variant="masonry" style={{columnCount:3}} gap={8}>
                            {adsList.map((item) => {
                                const labelId = `checkbox-list-label-${item.id}`;

                                return (
                                    // <Grid item  xs="auto">
                                    <ListItemButton role={undefined} onClick={handleToggle(item)} dense>
                                        <ImageListItem key={item.id}>
                                            {item.fileType === "image" && <img
                                                src={`${item.file}`}
                                                srcSet={`${item.file} 2x`}
                                                alt={item.title}
                                                style={{width: "300px"}}
                                            />
                                            }
                                            {item.fileType === "video" && <video
                                                src={`${item.file}`}
                                                style={{width: "300px"}}

                                            />}
                                            <ImageListItemBar
                                                title={item.advertisementName}
                                                subtitle={item.fileType}
                                                actionIcon={
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            edge="start"
                                                            checked={checked.indexOf(item) !== -1}
                                                            tabIndex={-1}
                                                            disableRipple
                                                            inputProps={{'aria-labelledby': labelId}}
                                                            sx={{color: "#fff"}}
                                                        />
                                                    </ListItemIcon>
                                                }
                                            />
                                        </ImageListItem>
                                    </ListItemButton>
                                    // </Grid>
                                );
                            })}
                        </ImageList>
                    </Grid>
                    :
                    <Grid container width={"400px"} direction={"column"} flexDirection={"row"} justifyContent={"center"}
                          alignItems={"center"}>
                        <CustomIndicator/>
                    </Grid>
            }

        </Box>
    );
}

export default SelectionView;
