import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import DashboardLayout from "./components/Dashboard/DashboardLayout";
import Dashboard from "./views/admin/Dashboard";
import Collection from "./views/admin/collection/Collection";
import Branch from "./views/admin/branch/Branch";
import Advertisement from "./views/admin/advertisement/Advertisement";
import Register from "./views/auth/Register";

const AppRoutes = () => {
    const [companyId, setCompanyId] = useState("");

    useEffect(() => {
        console.log("AppRoute companyId : ", companyId)
    }, [companyId])
    return (

            <Router>
                <Switch>
                    {/*<Route exact path="/">*/}
                    {/*    <Login companyId={companyId} setCompanyId={setCompanyId}/>*/}
                    {/*</Route>*/}
                    <DashboardLayout>
                    <Route path="/register">
                        <Register />
                    </Route>
                        <Route exact path="/">
                            <Collection companyId={companyId}/>
                        </Route>
                        <Route path="/dashboard">
                            <Dashboard/>
                        </Route>
                        <Route exact path="/collection">
                            <Collection companyId={companyId}/>
                        </Route>
                        <Route path="/advertisement">
                            <Advertisement/>
                        </Route>
                        <Route path="/branch_register">
                            <Branch/>
                        </Route>

                        {/*<Route render={() => <Redirect to={{pathname: "/"}} />} />*/}
                    </DashboardLayout>
                </Switch>

            </Router>
    );
};

export default AppRoutes;
