import React, {useEffect, useState} from 'react';
import {Box, Grid, Paper, TextField} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {addDoc, collection, doc, onSnapshot, query, setDoc, where} from "firebase/firestore";
import {db} from "../../../firebase";
import {useSnackbar} from "notistack";
import Autocomplete from '@mui/material/Autocomplete';
import {defaultTimezones} from "../../../components/TimeZonesList";


const BranchRegister = (props) => {

    const {rowData, onClose} = props;
    const [loading, setLoading] = React.useState(false);
    const [companyName, setCompanyName] = useState(rowData?rowData.companyName:"");
    const [branchName, setBranchName] = useState(rowData?rowData.branchName:"");
    const [timezone, setTimezone] = useState(rowData && {name:rowData.timezone});
    const [address, setAddress] = useState(rowData?rowData.address:"");
    const [companyList, setCompanyList] = useState([]);
    const [allCompanyList, setAllCompanyList] = useState([]);

    useEffect(() => {
        let company = companyList.filter((ele, ind) => ind === companyList.findIndex(elem => elem.companyName === ele.companyName))
        setAllCompanyList(company);

    }, [companyList]);

    const {enqueueSnackbar} = useSnackbar();

    const caseInSensitive = (x, y) => {
        return x.toLocaleLowerCase() === y.toLocaleLowerCase()
    }

    const caseSensitive = (x, y) => {
        if (x.toLocaleLowerCase() === y.toLocaleLowerCase()) {
            return x !== y;
        }
    }

    const submitDetails = async (e) => {
        e.preventDefault();
        setLoading(true);

        console.log("companyName: ", companyName);
        console.log("timezone: ", rowData?timezone.name:timezone);


        if (companyName === "") {
            enqueueSnackbar("Company Name Empty!", {variant: "error"});
        } else if (branchName === "") {
            enqueueSnackbar("Branch City Empty!", {variant: "error"});
        } else if (timezone === undefined) {
            enqueueSnackbar("TimeZone Empty!", {variant: "error"});
        } else if (address === "") {
            enqueueSnackbar("Address Empty!", {variant: "error"});
        } else if (companyList.some(e => caseSensitive(e.companyName, companyName))) {
            enqueueSnackbar("The company you are trying to register is already exists.", {variant: "warning"});
        } else if (companyList.some(e => caseSensitive(e.branchName, branchName))) {
            enqueueSnackbar("The branch you are trying to register is already exists.", {variant: "warning"});
        } else if (companyList.some(e => caseInSensitive(e.companyName, companyName) && caseInSensitive(e.branchName, branchName) && caseInSensitive(e.timezone, rowData?timezone.name:timezone) )) {
            enqueueSnackbar("Already Added", {variant: "warning"});
        } else if(rowData){
            let payload = {companyName, branchName, address, timezone:timezone.name};
            const docRef = doc(db, "company", rowData.id)
            await setDoc(docRef, payload).then(() => {
                enqueueSnackbar("Updated the branch!", {variant:"success"});
                setCompanyName("")
                setBranchName("")
                setAddress("")
                setLoading(false);
                onClose();
            })

        } else {
            await addDoc(collection(db, "company"), {
                companyName: companyName.trim(),
                branchName: branchName.trim(),
                timezone: timezone,
                address: address.trim()
            }).then(() => {
                console.log("After: ", companyList);
                enqueueSnackbar("Done", {variant: "success"});
                setCompanyName("")
                setBranchName("")
                setAddress("")
                setLoading(false);
            })

        }
        setLoading(false);
    };

    useEffect(() => {
        let pp = companyList.filter((ele, ind) => ind === companyList.findIndex(elem => elem.companyName === ele.companyName))
        console.log("Sorted : ", pp)
    }, [companyList])

    useEffect(() => {
        const colRef = collection(db, "company");
        const q = query(colRef, where("fileType", "==", "image"));
        onSnapshot(
            colRef,
            (snapShot) => {
                let list = [];
                snapShot.docs.forEach((doc) => {
                    list.push({id: doc.id, ...doc.data()});
                });
                setCompanyList(list);
            },
            (error) => {
                console.log(error);
            }
        )
    }, [])
    return (
        <Box sx={{borderRadius: 0, justifyContent: "center", alignItems: "center", display: "flex"}}>
            <Grid container justifyContent="space-around" component={!rowData && Paper}
                  item lg={5.9} md={6} sm={12} xs={12} direction={"column"}
                  sx={{
                      // background: "#FBFBFB",
                      borderRadius: "10px",
                      padding: rowData? "0 50px":"50px",
                      minWidth:"500px"
                  }}>
                <form onSubmit={submitDetails}>

                    <Grid container direction={"column"}>
                        {/*/!* ------------------Company Name--------------------- *!/*/}
                        <Grid item sx={{paddingTop: "20px"}}>
                            <Grid item sx={{}}>
                                <Autocomplete
                                    freeSolo
                                    id="comName"
                                    value={companyName}
                                    onChange={(e, value) => setCompanyName(value)}
                                    options={allCompanyList.map((option) => option.companyName)}
                                    renderInput={(params) => (
                                        <TextField
                                            required
                                            onChange={(e, value) => setCompanyName(e.target.value)}
                                            options={allCompanyList.map((option) => option.companyName)}
                                            {...params}
                                            label="Company Name"
                                            value={companyName}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>

                        {/*/!* ------------------Company Name--------------------- *!/*/}
                        {/*<Grid item>*/}
                        {/*    <Grid item sx={{}}>*/}
                        {/*        <TextField margin="dense" id="outlined-basic"*/}
                        {/*                   sx={{width: "100%", minWidth: "150px"}}*/}
                        {/*                   variant="outlined"*/}
                        {/*                   label={"Company Name"}*/}
                        {/*                   value={companyName}*/}
                        {/*                   onInput={(e) => setCompanyName(e.target.value)}/>*/}
                        {/*        /!*<CustomTextField/>*!/*/}
                        {/*    </Grid>*/}
                        {/*</Grid>*/}

                        {/* -----------------------Branch Name---------------------------- */}

                        <Grid container item direction={"column"}>
                            <Grid item sx={{paddingTop: "20px"}}>
                                {/*<Typography sx={{color: "#7b92ec",}}>Password</Typography>*/}
                                <Grid item sx={{}}>
                                    <TextField margin="dense" id="outlined-basic"
                                               required fullWidth
                                               sx={{width: "100%", minWidth: "150px"}}
                                               variant="outlined" label={"Branch City"}
                                               value={branchName}
                                               onInput={(e) => setBranchName(e.target.value)}/>
                                    {/*<CustomTextField/>*/}
                                </Grid>
                            </Grid>
                        </Grid>

                        {/*/!* ------------------Time Zone--------------------- *!/*/}
                        <Grid container item direction={"column"}>
                            <Grid item sx={{paddingTop: "20px"}}>
                                <Grid item sx={{}}>
                                    <Autocomplete
                                        required
                                        id="tags-standard"
                                        options={defaultTimezones}
                                        value={timezone}
                                        onChange={(e, value) => rowData?setTimezone(value):setTimezone(value.name)}
                                        getOptionLabel={(option) => (option.name)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Time Zone"
                                            />
                                        )}
                                    />

                                </Grid>
                            </Grid>
                        </Grid>

                        {/* -----------------------Address---------------------------- */}

                        <Grid container item direction={"column"}>
                            <Grid item sx={{paddingTop: "20px"}}>
                                {/*<Typography sx={{color: "#7b92ec",}}>Password</Typography>*/}
                                <Grid item sx={{}}>
                                    <TextField margin="dense" id="outlined-basic"
                                               required
                                               sx={{width: "100%", minWidth: "150px"}}
                                               variant="outlined" label={"Address"}
                                               value={address}
                                               onInput={(e) => setAddress(e.target.value)}/>
                                    {/*<CustomTextField/>*/}
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid sx={{paddingTop: "30px"}}>
                        <LoadingButton type="submit" loading={loading} variant={"contained"} color={"primary"}
                                       sx={{width: "100%", height: "50px", borderRadius: "10px"}}
                                       >{rowData ? "Update Branch" : "Register"}</LoadingButton>
                    </Grid>
                </form>
            </Grid>

        </Box>
    );
};

export default BranchRegister;
