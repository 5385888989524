import React, {useEffect, useRef, useState} from 'react';
import {Box, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField} from "@mui/material";
import {db, storage} from "../../../firebase";
import {addDoc, collection, doc, onSnapshot, setDoc,} from "firebase/firestore";
import {useSnackbar} from "notistack";
import {LoadingButton} from "@mui/lab";
import CustomUpload from "../../../components/CustomUpload";
import {getDownloadURL, ref, uploadBytesResumable} from "firebase/storage";
import {v4} from "uuid";

const CreateCollection = (props) => {

    const {rowData, onClose} = props;
    const [loading, setLoading] = React.useState(false);
    const [companyName, setCompanyName] = useState(rowData?rowData.companyName:"");
    const [branchName, setBranchName] = useState(rowData?rowData.branchName:"");
    const [orientation, setOrientation] = useState(rowData?rowData.orientation:"");
    const [collectionName, setCollectionName] = useState(rowData?rowData.collectionName:"");

    const [companyList, setCompanyList] = useState([]);
    const [collectionList, setCollectionList] = useState([]);
    const [allCompanyList, setAllCompanyList] = useState([]);
    const [allBranchList, setAllBranchList] = useState([]);

    const [file, setFile] = useState("");
    const [fileName, setFileName] = useState(rowData?rowData.fileName:"");
    const [fileType, setFileType] = useState("");
    const [imageWidth, setImageWidth] = useState(0);
    const [imageHeight, setImageHeight] = useState(0);

    let fileRef = useRef(null);

    const { enqueueSnackbar } = useSnackbar();

    const caseInSensitive = (x,y) => {
        return x.toLocaleLowerCase() === y.toLocaleLowerCase()
    }

    const submitDetails = async () => {
        setLoading(true);
        const tz = companyList.filter(e => e.companyName === companyName && e.branchName === branchName)[0].timezone;
        let payload = {companyName, branchName, collectionName, orientation, schedules:[], fileName, fileType, imageWidth, imageHeight, file:"", timezone:tz};
        const colRef = collection(db, "collection");

        if(companyName === ""){
            enqueueSnackbar("Company name empty!", {variant:"error"});
        } else if(branchName === ""){
            enqueueSnackbar("Branch city empty!", {variant:"error"});
        } else if(orientation === ""){
            enqueueSnackbar("Orientation empty!", {variant:"error"});
        } else if(collectionName.match(/^ *$/) !== null){
            enqueueSnackbar("Collection name empty!", {variant:"error"});
        } else if(file !== "" && fileType !== "image"){
            enqueueSnackbar("Please upload valid file type!", {variant:"error"});
        } else if (collectionList.some(e => caseInSensitive(e.companyName, companyName) && caseInSensitive(e.branchName, branchName) && caseInSensitive(e.collectionName, collectionName) && caseInSensitive(e.orientation, orientation) && caseInSensitive(e.fileName, fileName) )){
            enqueueSnackbar("Already added!", {variant:"warning"});
        } else if (file !== ""){
            const storageRef = ref(storage, file.name + v4());
            const uploadTask = uploadBytesResumable(storageRef, file);
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const progress =
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log("Upload is " + progress + "% done");
                    setLoading(true);
                    switch (snapshot.state) {
                        case "paused":
                            console.log("Upload is paused");
                            break;
                        case "running":
                            console.log("Upload is running");
                            break;
                        default:
                            break;
                    }
                },
                (error) => {
                    console.log(error);
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                        payload = {companyName, branchName, collectionName, orientation, schedules:[], fileName, fileType, imageWidth, imageHeight, file:downloadURL, timezone:tz};

                        if(rowData){
                            const docRef = doc(db, "collection", rowData.id)
                            await setDoc(docRef, payload).then(() => {
                                enqueueSnackbar("Updated the collection!", {variant:"success"});
                                setCompanyName("")
                                setBranchName("")
                                setCollectionName("")
                                setOrientation("")
                                setFile("");
                                setFileName("");
                                setLoading(false);
                                onClose();
                            })

                        } else {
                            const docRef = await addDoc(colRef, payload).then(() => {
                                enqueueSnackbar("Created the collection!", {variant:"success"});
                                setCompanyName("")
                                setBranchName("")
                                setCollectionName("")
                                setOrientation("")
                                setFile("");
                                setFileName("");
                                setLoading(false);
                            })
                        }
                    });
                }
            );
        } else {
            if(rowData){
                let payload = {companyName, branchName, collectionName, orientation, schedules:[], fileName:rowData.fileName, fileType:rowData.fileType, imageWidth:rowData.imageWidth, imageHeight:rowData.imageHeight, file:rowData.file, timezone:tz};

                const docRef = doc(db, "collection", rowData.id)
                await setDoc(docRef, payload).then(() => {
                    enqueueSnackbar("Updated the collection!", {variant:"success"});
                    setCompanyName("")
                    setBranchName("")
                    setCollectionName("")
                    setOrientation("")
                    setLoading(false);
                    onClose();
                })

            } else {
                const docRef = await addDoc(colRef, payload).then(() => {
                    enqueueSnackbar("Created the collection!", {variant:"success"});
                    setCompanyName("")
                    setBranchName("")
                    setCollectionName("")
                    setOrientation("")
                    setLoading(false);
                })
            }
        }



        setLoading(false);
    };

    useEffect(() => {
        let company = companyList.filter( (ele, ind) => ind === companyList.findIndex( elem => elem.companyName === ele.companyName))
        setAllCompanyList(company);
        let branch = companyList.filter( (ele, ind) => ind === companyList.findIndex( elem => elem.branchName === ele.branchName))
        setAllBranchList(branch);
    }, [companyList, collectionList]);

    useEffect(() =>{
        const comRef = collection(db, "company");
        const colRef = collection(db, "collection");
        onSnapshot(
            comRef,
            (snapShot) => {
                let list = [];
                snapShot.docs.forEach((doc) => {
                    list.push({id: doc.id, ...doc.data()});
                });
                setCompanyList(list);
                console.log("-----set Company List---- ");
            },
            (error) => {
                console.log(error);
            }
        )

        onSnapshot(
            colRef,
            (snapShot) => {
                let list = [];
                snapShot.docs.forEach((doc) => {
                    list.push({id: doc.id, ...doc.data()});
                });
                setCollectionList(list);
                console.log("-----set Collection List---- ");
            },
            (error) => {
                console.log(error);
            }
        )
    },[])

    return (
        <Box sx={{borderRadius: 0, justifyContent: "center", alignItems: "center", display:"flex"}}>
            <Grid container justifyContent="space-around" component={!rowData && Paper}
                  item lg={5.9} md={6} sm={12} xs={12} direction={"column"}
                  sx={{
                      // background: "#d4ec7b",
                      borderRadius: "10px",
                      padding: rowData? "0 50px":"50px",
                      minWidth:"500px"
                  }}>
                <form action="#" method="post">

                    <Grid container direction={"column"}>
                        {/* ------------------Company Name--------------------- */}
                        <Grid container item direction={"column"}>
                            <Grid item sx={{paddingTop: "20px"}}>
                                <FormControl margin="dense" fullWidth>
                                    <InputLabel id="demo-simple-select-label">Company</InputLabel>
                                    <Select sx={{width: "100%", minWidth: "150px", borderRadius: "10px"}} variant="outlined"
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={companyName}
                                            label="Company"
                                            disabled={rowData}
                                            onChange={(e) => {
                                                setCompanyName(e.target.value);
                                                setAllBranchList(companyList.filter( (ele, ind) => ind === companyList.findIndex( elem => elem.branchName === ele.branchName && elem.companyName === e.target.value)));
                                            }}
                                    >
                                        {allCompanyList.map((value) =>
                                            <MenuItem key={value.id} value={value.companyName} >{value.companyName}</MenuItem>
                                        )}
                                        {!allCompanyList.length && <MenuItem disabled value={"None"}>Please register a branch</MenuItem>}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        {/* -----------------------Branch Name---------------------------- */}

                        <Grid container item direction={"column"}>
                            <Grid item sx={{paddingTop: "20px"}}>
                                <FormControl margin="dense" fullWidth>
                                    <InputLabel id="demo-simple-select-label">Branch City</InputLabel>
                                    <Select sx={{width: "100%", minWidth: "150px", borderRadius: "10px"}} variant="outlined"
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={branchName}
                                            disabled={rowData}
                                            label="Branch City"
                                            onChange={(e) => {
                                                setBranchName(e.target.value);
                                                setAllCompanyList(companyList.filter( (ele, ind) => ind === companyList.findIndex( elem => elem.companyName === ele.companyName && elem.branchName === e.target.value)));
                                            }}
                                    >
                                        {allBranchList.map((value) =>
                                            <MenuItem key={value.id} value={value.branchName} >{value.branchName}</MenuItem>
                                        )}
                                        {!allBranchList.length && <MenuItem disabled value={"None"}>Please register a branch</MenuItem>}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        {/* -----------------------Orientation---------------------------- */}

                        <Grid container item direction={"column"}>
                            <Grid item sx={{paddingTop: "20px"}}>
                                <FormControl margin="dense" fullWidth>
                                    <InputLabel id="demo-simple-select-label">Orientation</InputLabel>
                                    <Select sx={{width: "100%", minWidth: "150px", borderRadius: "10px"}} variant="outlined"
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={orientation}
                                            label="Orientation"
                                            onChange={(e) => {
                                                setOrientation(e.target.value);
                                            }}
                                    >

                                            <MenuItem key={1} value={"Landscape"} >Landscape</MenuItem>
                                            <MenuItem key={2} value={"Portrait"} >Portrait</MenuItem>
                                     </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        {/* -----------------------Collection Name---------------------------- */}

                        <Grid container item direction={"column"}>
                            <Grid item sx={{paddingTop: "20px"}}>
                                {/*<Typography sx={{color: "#7b92ec",}}>Password</Typography>*/}
                                <Grid item sx={{}}>
                                    <TextField margin="dense" id="outlined-basic"
                                               sx={{width: "100%", minWidth: "150px"}}
                                               variant="outlined" label={"Collection Name"}
                                               value={collectionName}
                                               onInput={(e) => setCollectionName(e.target.value)}/>
                                    {/*<CustomTextField/>*/}
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* -----------------------File---------------------------- */}

                        <CustomUpload title={"Upload Default Image"} fileRef={fileRef} file={file} setFile={setFile}
                                      setFileType={setFileType} setImageWidth={setImageWidth} imageAccess editMode
                                      setImageHeight={setImageHeight}  setFileName={setFileName} fileName={fileName}/>

                    </Grid>
                    <Grid sx={{paddingTop: "30px"}}>
                        <LoadingButton loading={loading} variant={"contained"} color={"primary"}
                                       sx={{width: "100%", height:"50px", borderRadius:"10px"}} onClick={() => submitDetails()}>{rowData?"Update Collection":"Create Collection"}</LoadingButton>
                    </Grid>
                </form>
            </Grid>

        </Box>
    );
};

export default CreateCollection;
