import React, {useEffect, useState} from 'react';
import {collection, onSnapshot} from "firebase/firestore";
import {db} from "../../../firebase";
import {Grid} from "@mui/material";
import CollectionCard from "./CollectionCard";
import CustomIndicator from "../../../components/CustomIndicator";

const CollectionGallery = () => {
    const [collectionList, setCollectionList] = useState([]);

    useEffect(() => {
        const colRef = collection(db, "collection");

        onSnapshot(
            colRef,
            (snapShot) => {
                let list = [];
                snapShot.docs.forEach((doc) => {
                    list.push({id: doc.id, ...doc.data()});
                });
                setCollectionList(list);
                console.log("-----setCompanyList---- ");
            },
            (error) => {
                console.log(error);
            }
        )


    }, [])

    return (
        <Grid container direction={"column"} flexDirection={"row"} justifyContent={"flex-start"}
              alignItems={"center"} >

            {
                collectionList.length !== 0 ? collectionList.map((value) => {
                        return (
                            <Grid key={value.id} lg={4} md={6} xs={12} p={1}>
                                <CollectionCard data={value} title={value.collectionName} subtitle={value.companyName}
                                                description={value.branchName}
                                                image={value.file ? value.file:require("../../../assets/images/icon.png")}
                                />
                            </Grid>
                        )
                    }) :
                    <Grid container direction={"column"} flexDirection={"row"} justifyContent={"center"}
                          alignItems={"center"}>
                        <CustomIndicator />
                    </Grid>
            }

        </Grid>
    );
};

export default CollectionGallery;
