import React from 'react';
import {Button, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import CloudUploadTwoToneIcon from "@mui/icons-material/CloudUploadTwoTone";

const CustomUpload = (props) => {

    const { title, fileRef, file, setFile, fileName, setFileName, setFileType, setImageWidth, setImageHeight, imageAccess, editMode, setThumbnail} = props;

    const fileUpload = async (e) => {
        const uploadFile = e.target.files[0];

        setFile(uploadFile);
        setFileName(uploadFile.name);
        setFileType(uploadFile.type.split("/")[0]);
        if (uploadFile.type.split("/")[0] === "image" && imageAccess) {

            const reader = new FileReader();

            reader.readAsDataURL(uploadFile);
            console.log("Image :", reader.onload);
            reader.onload = (e) => {
                const image = new Image();
                image.src = e.target.result;
                image.onload = (e) => {
                    const height = e.target.height;
                    const width = e.target.width;
                    setImageWidth(width);
                    setImageHeight(height);
                    console.log("File Height : ", height)
                    console.log("File Width : ", width)
                };
            };

        } else if (uploadFile.type.split("/")[0] === "video") {
            const thumbnail = await getVideoCover(uploadFile);
            console.log("thumbnail : ", URL.createObjectURL(thumbnail))
            setThumbnail(thumbnail);
            //     const files = e.target.files;
            //
            //     let myFiles = Array.from(files);
            //
            //     const fileReader = new FileReader();
            //
            //     fileReader.onload = () => {
            //         if (fileReader.readyState === 2) {
            //             console.log("video", myFiles);
            //         }
            //     };
            //     fileReader.onloadstart = () => {
            //         console.log(null);
            //     };
            //     fileReader.onloadend = () => {
            //         console.log(fileReader.result);
            //     };
            //     if (e.target.files[0]) {
            //         console.log("Target : ",fileReader.readAsDataURL(e.target.files[0]));
            //
            //     }
        }

    }
    function getVideoCover(file, seekTo = 0.0) {
        console.log("getting video cover for file: ", file);
        return new Promise((resolve, reject) => {
            // load the file to a video player
            const videoPlayer = document.createElement('video');
            videoPlayer.setAttribute('src', URL.createObjectURL(file));
            videoPlayer.load();
            videoPlayer.addEventListener('error', (ex) => {
                reject("error when loading video file", ex);
            });
            // load metadata of the video to get video duration and dimensions
            videoPlayer.addEventListener('loadedmetadata', () => {
                // seek to user defined timestamp (in seconds) if possible
                if (videoPlayer.duration < seekTo) {
                    reject("video is too short.");
                    return;
                }
                // delay seeking or else 'seeked' event won't fire on Safari
                setTimeout(() => {
                    videoPlayer.currentTime = seekTo;
                }, 200);
                // extract video thumbnail once seeking is complete
                videoPlayer.addEventListener('seeked', () => {
                    console.log('video is now paused at %ss.', seekTo);
                    // define a canvas to have the same dimension as the video
                    const canvas = document.createElement("canvas");
                    canvas.width = videoPlayer.videoWidth;
                    canvas.height = videoPlayer.videoHeight;
                    // draw the video frame to canvas
                    const ctx = canvas.getContext("2d");
                    ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
                    // return the canvas image as a blob
                    ctx.canvas.toBlob(
                        blob => {
                            resolve(blob);
                        },
                        "image/jpeg",
                        0.75 /* quality */
                    );
                });
            });
        });
    }
    return (
        <Grid container item direction={"column"}>
            <Grid item sx={{paddingTop: "20px"}}>
                {/*<Typography sx={{color: "#7b92ec",}}>Password</Typography>*/}
                <Grid item direction={"column"} xs={12}
                      container
                      justifyContent="center">
                    <Button
                        variant={"outlined"}
                        component="label"
                        color={"inherit"}
                        sx={{
                            borderRadius: "10px", borderColor: "#C4C4C4", "&:hover": {
                                borderColor: "#303030",
                            }
                        }}
                    >
                        <Grid item container direction={"column"} justifyContent="center"
                              alignItems={"center"} p={10}>
                            <Typography variant={"body2"}
                                        align={"center"}>{file ? file.name : ""}</Typography>
                            {editMode && !file && <Typography variant={"body2"}
                                         align={"center"}>{fileName ? fileName : ""}</Typography>}
                            <CloudUploadTwoToneIcon/>
                            <Typography variant={"button"} align={"center"}>{title}</Typography>
                            <input
                                ref={fileRef}
                                type="file"
                                hidden
                                onChange={(e) => fileUpload(e)}
                            />
                        </Grid>
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default CustomUpload;
