import React, {useEffect, useState} from 'react';
import {
    Avatar,
    Backdrop,
    Box,
    Card,
    CardContent,
    CardHeader,
    CardMedia,
    Fade,
    Grid,
    IconButton,
    Modal,
    Typography
} from "@mui/material";
import {red} from "@mui/material/colors";
import CardMenu from "./CardMenu";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {collection, onSnapshot, query, where} from "firebase/firestore";
import {db} from "../../../firebase";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "90vw",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    flexGrow: 1,
    overflow: "auto",
    height: "95vh"
};
const CollectionCard = ({data, title, subtitle, description, image}) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);

    const [expanded, setExpanded] = React.useState(false);
    const [scheduleRows, setScheduleRows] = useState([]);

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleCloseModal = () => setOpen(false);

    useEffect(() => {
        console.log("---------------ROW----------- : ")
        const colRef = collection(db, "schedule");
        const q = query(colRef, where("collectionId", "==", data.id));
        onSnapshot(
            q,
            (snapShot) => {
                let list = [];
                snapShot.docs.forEach((doc) => {
                    list.push({id: doc.id, ...doc.data()});
                });
                setScheduleRows(list);
                console.log("scheduleRows : ", list);
        // console.log("data : ", data.file);
            },
            (error) => {
                console.log(error);
            }
        )
    }, [data]);

    useEffect(() => {
        console.log("scheduleRows : ", scheduleRows[0])

    }, [scheduleRows]);
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };


    return (
        <>
            <Card sx={{bgcolor: "#ffffff",  cursor:scheduleRows[0] === undefined ? "":"pointer"}}
                  onClick={() => scheduleRows[0] !== undefined && window.open(`https://display-client.web.app/${data.companyName}/${data.branchName}/${data.collectionName}`, "_blank")}
                >
                <CardHeader
                    avatar={
                        <Avatar sx={{bgcolor: red[500]}} aria-label="recipe">
                            {title.charAt(0)}
                        </Avatar>
                    }
                    // action={
                    //     <IconButton aria-label="settings" onClick={handleClick}>
                    //         <MoreVertIcon/>
                    //     </IconButton>
                    // }
                    title={title}
                    subheader={subtitle}
                />
                <CardMedia
                    component="img"
                    height="194"
                    image={image}
                    alt="Paella dish"
                    sx={{objectFit: 'contain',
                        backgroundRepeat: 'no-repeat',}}
                />
                <CardContent>
                    <Typography variant="body2" color="text.secondary" sx={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2,
                    }}>
                        {description}
                    </Typography>
                </CardContent>
            </Card>
            <CardMenu data={data} anchorEl={anchorEl} openMenu={openMenu} handleClose={handleClose}
                      ViewClick={handleOpen}/>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleCloseModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}

            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Grid container justifyContent={"space-between"} direction={"row"}>
                            <Grid item direction={"column"}>
                                <Typography id="transition-modal-title" variant="h6" component="h2">
                                    {title}
                                </Typography>
                                <Typography id="transition-modal-title" variant="body1" component="h2">
                                    {subtitle}
                                </Typography>
                            </Grid>
                            <Grid item direction={"column"}>
                                <IconButton aria-label="settings" onClick={handleCloseModal}>
                                    <CloseRoundedIcon/>
                                </IconButton>
                            </Grid>
                        </Grid>
                        <CardMedia
                            component="img"
                            height="500"
                            image={image}
                            alt="Paella dish"
                        />
                        <Typography id="transition-modal-description" sx={{mt: 2}}>
                            {description}
                        </Typography>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
};

export default CollectionCard;
