import React, {useEffect, useState} from 'react';
import {useSnackbar} from "notistack";
import {collection, deleteDoc, doc, onSnapshot, query, where} from "firebase/firestore";
import {db} from "../../../firebase";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import LinkRoundedIcon from "@mui/icons-material/LinkRounded";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import EnhancedTableToolbar from "../../../components/EnhancedTableToolbar";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import EnhancedTableHead from "../../../components/EnhancedTableHead";
import TableBody from "@mui/material/TableBody";
import Slide from '@mui/material/Slide';
import {TransitionProps} from '@mui/material/transitions';
import Schedule from "../schedule/Schedule";
import theme from "../../../theme";
import CustomDialog from "../../../components/CustomDialog";
import moment from "moment";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const scheduleMenuItems = [
    {id: 0, value: "date", text: "Date"},
    {id: 1, value: "title", text: "Title"},
    {id: 3, value: "duration", text: "Duration"},
];
const scheduleHeadCells = [
    {
        id: 'date',
        numeric: false,
        disablePadding: true,
        align: "left",
        sort: false,
        label: 'Date/Time',
    },
    {
        id: 'title',
        numeric: true,
        disablePadding: false,
        align: "left",
        sort: false,
        label: 'Title',
    },
    {
        id: 'duration',
        numeric: true,
        disablePadding: false,
        align: "left",
        sort: false,
        label: 'Duration',
    },
    {
        id: 'options',
        numeric: true,
        disablePadding: false,
        align: "left",
        sort:false,
        label: 'Edit/Delete',
    },
    {
        id: 'launch',
        numeric: true,
        disablePadding: false,
        align: "left",
        sort:false,
        label: 'Launch',
    }

];

function CollectionTableBody(props) {
    const {row, handleOpen, getComparator, stableSort} = props;
    const {enqueueSnackbar} = useSnackbar();
    const [open, setOpen] = useState(false);
    const [filterValue, setFilterValue] = useState('date');
    const [scheduleRows, setScheduleRows] = useState(row.schedules);
    const [scheduleId, setScheduleId] = useState();
    const [scheduleData, setScheduleData] = useState();

    useEffect(() => {
        console.log("---------------ROW----------- : ", row.id)
        const colRef = collection(db, "schedule");
        const q = query(colRef, where("collectionId", "==", row.id));
        onSnapshot(
            q,
            (snapShot) => {
                let list = [];
                snapShot.docs.forEach((doc) => {
                    list.push({id: doc.id, ...doc.data()});
                });
                setScheduleRows(list);
                console.log("scheduleRows : ", list);
            },
            (error) => {
                console.log(error);
            }
        )
    }, [row]);

    //dialog
    const [collectionDeleteDialogOpen, setCollectionDeleteDialogOpen] = React.useState(false);
    const [scheduleDeleteDialogOpen, setScheduleDeleteDialogOpen] = React.useState(false);
    const [scheduleDialogOpen, setScheduleDialogOpen] = React.useState(false);

    //sort
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('date');

    const deleteCollection = async (row) => {
        const docRef = doc(db, "collection", row.id);
        await deleteDoc(docRef).then(() => {
                setCollectionDeleteDialogOpen(false);
                enqueueSnackbar("Collection deleted successfully!", {variant: "success"})
            }
        );
    }

    const deleteSchedule = async () => {
        const docRef = doc(db, "schedule", scheduleId);
        await deleteDoc(docRef).then(() => {
            setScheduleDeleteDialogOpen(false);
                enqueueSnackbar("Schedule deleted successfully!", {variant: "success"})
            }
        );
    }


    const scheduleEdit = (scheduleData) => {
        setScheduleData(scheduleData);
        setScheduleDialogOpen(true);
    }

    return (
        <React.Fragment>
            <TableRow sx={{'& > *': {borderBottom: 'unset'}}}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.collectionName}
                </TableCell>
                <TableCell align="left">{row.companyName}</TableCell>
                <TableCell align="left">{row.branchName}</TableCell>
                <TableCell align="left">{row.orientation}</TableCell>
                <TableCell align="left">{row.timezone}</TableCell>
                <TableCell align="center">
                    <Fab size="small" aria-label="edit"
                         sx={{marginRight: 1, color: "#000000", backgroundColor: "#fff"}}
                         onClick={() => handleOpen(row)}>
                        <EditIcon/>
                    </Fab>
                    <Fab size="small" aria-label="add" sx={{marginLeft: 1, color: "#ff0000", backgroundColor: "#fff"}}
                         onClick={() => setCollectionDeleteDialogOpen(true)}>
                        <DeleteForeverRoundedIcon/>
                    </Fab>
                </TableCell>
                <TableCell align="left">
                    <Fab size="small" aria-label="edit"
                         sx={{marginRight: 1, color: "#F18628", backgroundColor: "#fff"}}
                         onClick={() => window.open(`https://display-client.web.app/${row.companyName}/${row.branchName}/${row.collectionName}`, "_blank")}>
                        <LinkRoundedIcon/>
                    </Fab>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell  style={{paddingBottom: 0, paddingTop: 0, backgroundColor:theme.palette.secondary.main}} colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{margin: 1}}>
                            <EnhancedTableToolbar tableName={row.collectionName}
                                                  headButton={"Schedule"}
                                                  ModalPopup={() => setScheduleDialogOpen(true)}/>
                            <TableContainer sx={{maxHeight: "55vh"}}>
                                <Table size="medium" aria-label="purchases">
                                    <EnhancedTableHead
                                        order={order}
                                        orderBy={orderBy}
                                        setOrder={setOrder}
                                        setOrderBy={setOrderBy}
                                        spaceCell={false}
                                        headCells={scheduleHeadCells}
                                    />
                                    <TableBody>
                                        {stableSort(scheduleRows, getComparator(order, orderBy)).map((schedule) => (
                                            <TableRow key={schedule.date}>
                                                <TableCell component="th" scope="row">
                                                    {moment(schedule.scheduleDateTime.toDate()).format('MMMM Do YYYY, h:mm a')}
                                                    {/*{schedule.scheduleDateTime}*/}
                                                    {/*{moment(schedule.scheduleDateTime.toDate()).format('MMMM Do YYYY')}&nbsp;&nbsp;&nbsp;&nbsp;{moment(schedule.scheduleDateTime.toDate()).format('hh:mm a')}*/}
                                                </TableCell>
                                                <TableCell>{schedule.scheduleName}</TableCell>
                                                <TableCell align="left">{schedule.duration}</TableCell>
                                                <TableCell align="left">
                                                    <Fab size="small" aria-label="edit"
                                                         sx={{marginRight: 1, color: "#000000", backgroundColor: "#fff"}}
                                                         onClick={() => scheduleEdit(schedule)}>
                                                        <EditIcon/>
                                                    </Fab>
                                                    <Fab size="small" aria-label="add" sx={{marginLeft: 1, color: "#ff0000", backgroundColor: "#fff"}}
                                                         onClick={() => {
                                                             setScheduleDeleteDialogOpen(true)
                                                             setScheduleId(schedule.id);
                                                         }}>
                                                        <DeleteForeverRoundedIcon/>
                                                    </Fab>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Fab size="small" aria-label="edit"
                                                         sx={{marginRight: 1, color: "#F18628", backgroundColor: "#fff"}}
                                                         onClick={(elem) => elem.requestFullscreen(window.open(`https://display-client.web.app/${row.companyName}/${row.branchName}/${row.collectionName}/${schedule.scheduleName}`, "_blank"))}>
                                                        <LinkRoundedIcon/>
                                                    </Fab>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>

            {/*-----------Delete Dialog Collection --------*/}
            <Dialog
                open={collectionDeleteDialogOpen}
                keepMounted
                onClose={() => setCollectionDeleteDialogOpen(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Delete item"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Are you sure you want to delete the item ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setCollectionDeleteDialogOpen(false)} variant={"outlined"}>{"No thanks"}</Button>
                    <Button onClick={() => deleteCollection(row)} variant={"contained"}>{"Delete"}</Button>
                </DialogActions>
            </Dialog>

            {/*-----------Delete Dialog Schedule --------*/}
            <Dialog
                open={scheduleDeleteDialogOpen}
                keepMounted
                onClose={() => setScheduleDeleteDialogOpen(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Delete item"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Are you sure you want to delete the item ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setScheduleDeleteDialogOpen(false)} variant={"outlined"}>{"No thanks"}</Button>
                    <Button onClick={() => deleteSchedule()} variant={"contained"}>{"Delete"}</Button>
                </DialogActions>
            </Dialog>

            {/*---------Schedule Dialog----------*/}
            <CustomDialog
                fullScreen
                closeBtn
                open={scheduleDialogOpen} title={"Create Schedule"}
                onClose={() => setScheduleDialogOpen(false)}
            >
                <Schedule rowData={row} scheduleData={scheduleData}/>
            </CustomDialog>
        </React.Fragment>
    );
}

export default CollectionTableBody;
