import React, {useEffect} from 'react';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import TableContainer from "@mui/material/TableContainer";
import {Avatar, Fab, Paper, TableCell, TextField} from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import EnhancedTableToolbar from "../../../components/EnhancedTableToolbar";
import Box from "@mui/material/Box";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import SelectionView from "./SelectionView";
import CustomDialog from "../../../components/CustomDialog";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Schedule from "./Schedule";
import CreateAdvertisement from "../advertisement/CreateAdvertisement";

const DragTable = (props) => {

    const {selectAds, setSelectAds, rowData} = props;


    //Modal
    const [open, setOpen] = React.useState(false);
    const [scheduleDialogOpen, setScheduleDialogOpen] = React.useState(false);

    useEffect(() => {
        console.log("Users :", selectAds);
        console.log("Selected :", selectAds);

    }, [selectAds]);

    const handleDragEnd = (e) => {
        if (!e.destination) return;
        let tempData = Array.from(selectAds);
        let [source_data] = tempData.splice(e.source.index, 1);
        tempData.splice(e.destination.index, 0, source_data);
        setSelectAds(tempData);
    }

    const removeCell = (event, value) => {
        console.log("Remove : ", event)
        console.log("Remove index : ", value)
        const filteredArray = selectAds.filter((item, index) => index !== value);
        setSelectAds(filteredArray);
    }

    const handleClose = () => setOpen(false);
    const handleOpen = () => {
        setOpen(true);
    }

    const changeDuration = (event,index, item) => {
      console.log("index : ", index)
      console.log("Change Time : ", event.target.value)
      console.log("Change Due : ", item)
        console.log("Ready to Change : ", selectAds[index])
        Object.defineProperty(selectAds[index], "duration", {value : parseInt(event.target.value)});
        console.log("After Change : ", selectAds[index])
    }
    return (
        <Box component={Paper} sx={{marginTop: "20px"}}>
            <EnhancedTableToolbar tableName={"Schedule"}
                                  headButton={"Insert ADS"} headButton2={"Create ADS"} ModalPopup={handleOpen} ModalPopup2={() => setScheduleDialogOpen(true)}/>
            <TableContainer sx={{height: "55vh"}}>
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Table sx={{minWidth: 650}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{width: "100vw"}}/>
                                <TableCell sx={{width: "200vw"}} align="left">Thumbnail</TableCell>
                                <TableCell sx={{width: "100vw"}} align="left">Title</TableCell>
                                <TableCell sx={{width: "200vw"}} align="left">Type</TableCell>
                                <TableCell sx={{width: "100vw"}} align="left">Duration(s)</TableCell>
                                <TableCell sx={{width: "100vw"}} align="left">Remove</TableCell>
                            </TableRow>
                        </TableHead>
                        <Droppable droppableId="droppable-1">
                            {(provider) => (
                                <TableBody
                                    ref={provider.innerRef}
                                    {...provider.droppableProps}
                                >
                                    {selectAds?.map((item, index) => (
                                        <Draggable
                                            key={item.scheduleItemId}
                                            draggableId={item.scheduleItemId}
                                            index={index}
                                        >
                                            {(provider) => (
                                                <TableRow key={item.scheduleItemId}
                                                          sx={{'&:last-child td, &:last-child th': {border: 0}}} {...provider.draggableProps}
                                                          ref={provider.innerRef}>
                                                    <TableCell
                                                        sx={{width: "100vw"}}   {...provider.dragHandleProps}>
                                                        <DragIndicatorIcon/> </TableCell>
                                                    {
                                                        item.fileType === "image" ?
                                                            <TableCell sx={{width: "200vw"}}
                                                                       align="left">
                                                                <Avatar src={item.file} sx={{width: 56, height: 56}}/>
                                                            </TableCell>
                                                            :
                                                            <TableCell sx={{width: "200vw"}}
                                                                       align="left">
                                                                <Avatar sx={{width: 56, height: 56}}>
                                                                    <video width={56} height={56} src={item.file}/>
                                                                </Avatar>
                                                            </TableCell>}
                                                    <TableCell sx={{width: "100vw"}}
                                                               align="left">{item.advertisementName}</TableCell>
                                                    <TableCell sx={{width: "100vw"}}
                                                               align="left">{item.fileType}</TableCell>
                                                    {item.fileType === "image" ?
                                                        <TableCell sx={{width: "100vw"}}
                                                                   align="left">

                                                            <TextField
                                                                onChange={(e) => changeDuration(e,index,item)}
                                                                InputProps={{inputProps: {min: 1}}}
                                                                type={"number"} defaultValue={item.duration}
                                                                variant={"standard"} size={"small"}/>

                                                        </TableCell>
                                                        : <TableCell sx={{width: "100vw"}}
                                                                     align="left">{item.duration}</TableCell>}
                                                    <TableCell sx={{width: "100vw"}}
                                                               align="left">
                                                        <Fab size="small" aria-label="add"
                                                             sx={{color: "#ff0000", backgroundColor: "#fff"}}
                                                             onClick={(e) => removeCell(item, index)}>
                                                            <DeleteForeverRoundedIcon/>
                                                        </Fab>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provider.placeholder}
                                </TableBody>
                            )}
                        </Droppable>
                    </Table>
                </DragDropContext>
            </TableContainer>
            <CustomDialog
                footer
                onClose={handleClose}
                open={open} title={"Choose Advertisements"}>
                <SelectionView checked={selectAds} setChecked={setSelectAds} rowData={rowData}/>
            </CustomDialog>

            {/*---------Advertisement Dialog----------*/}
            <CustomDialog
                closeBtn
                popup
                open={scheduleDialogOpen} title={"Create Schedule"}
                onClose={() => setScheduleDialogOpen(false)}
            >
                <CreateAdvertisement/>
            </CustomDialog>
        </Box>
    );
};

export default DragTable;
