import * as React from 'react';
import {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import {collection, onSnapshot,} from "firebase/firestore";
import {db} from "../../../firebase";
import EnhancedTableToolbar from "../../../components/EnhancedTableToolbar";
import TablePagination from "@mui/material/TablePagination";
import EnhancedTableHead from "../../../components/EnhancedTableHead";
import CreateCollection from "./CreateCollection";
import CollectionTableBody from "./CollectionTableBody";
import CustomDialog from "../../../components/CustomDialog";
import {getComparator, stableSort} from "../../../utills/UtilFunction";
const collectionMenuItems = [
    {id: 0, value: "all", text: "All"},
    {id: 1, value: "collectionName", text: "Collection Name"},
    {id: 2, value: "companyName", text: "Company Name"},
    {id: 3, value: "branchName", text: "Branch City"},
    {id: 4, value: "timezone", text: "Timezone"},
];
const collectionHeadCells = [
    {
        id: 'collectionName',
        numeric: false,
        disablePadding: true,
        align: "left",
        sort: true,
        label: 'Collection Name',
    },
    {
        id: 'companyName',
        numeric: true,
        disablePadding: false,
        align: "left",
        sort: true,
        label: 'Company Name',
    },
    {
        id: 'branchName',
        numeric: true,
        disablePadding: false,
        align: "left",
        sort: true,
        label: 'Branch City',
    },
    {
        id: 'orientation',
        numeric: true,
        disablePadding: false,
        align: "left",
        sort: true,
        label: 'Orientation',
    },
    {
        id: 'timezone',
        numeric: true,
        disablePadding: false,
        align: "left",
        sort: true,
        label: 'Timezone',
    },
    {
        id: 'options',
        numeric: true,
        disablePadding: false,
        align: "center",
        sort: false,
        label: 'Edit/Delete',
    },
    {
        id: 'launch',
        numeric: true,
        disablePadding: false,
        align: "left",
        sort: false,
        label: 'Launch',
    }

];


const CollectionTable = () => {

    const [collectionList, setCollectionList] = useState([]);
    const [filterValue, setFilterValue] = React.useState('all');
    const [rows, setRows] = useState([]);
    //Modal
    const [rowData, setRowData] = React.useState("");
    const [open, setOpen] = React.useState(false);

    //sort
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('collectionName');

    //pagination
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);


    //search functions
    const requestSearch = (searchedVal) => {
        let keyword = searchedVal.target.value;
        let newData = collectionList.filter(item => {
            // console.log("requestSearch : ", item.firstName)
            if (keyword === "") return item;
            else if ((item.collectionName.toLowerCase().includes(keyword.toLowerCase()) || item.companyName.toLowerCase().includes(keyword.toLowerCase()) || item.branchName.toLowerCase().includes(keyword.toLowerCase())) && filterValue === "all") {
                return item;
            } else if (item.collectionName.toLowerCase().includes(keyword.toLowerCase()) && filterValue === "collectionName") {
                return item;
            } else if (item.companyName.toLowerCase().includes(keyword.toLowerCase()) && filterValue === "companyName") {
                return item;
            } else if (item.branchName.toLowerCase().includes(keyword.toLowerCase()) && filterValue === "branchName") {
                return item;
            } else if (item.timezone.toLowerCase().includes(keyword.toLowerCase()) && filterValue === "timezone") {
                return item;
            }

        })
        setRows(newData);
    };
    const handleChange = (event) => {
        setFilterValue(event.target.value);
    };


    //start load collection data
    useEffect(() => {
        const colRef = collection(db, "collection");
        onSnapshot(
            colRef,
            (snapShot) => {
                let list = [];
                snapShot.docs.forEach((doc) => {
                    list.push({id: doc.id, ...doc.data()});
                });
                setCollectionList(list);
                setRows(list);
                console.log("-----setCompanyList---- ");
            },
            (error) => {
                console.log(error);
            }
        )
    }, [])


    //pagination functions
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // modal actions
    const handleClose = () => setOpen(false);
    const handleOpen = (row) => {
        console.log("setRowData : ", row);
        setRowData(row);
        setOpen(true);
    };
    return (
        <Box sx={{width: '100%',}}>
            <Paper sx={{width: '100%', mb: 2,}}>
                <EnhancedTableToolbar tableName={"Collection Table"} menuItems={collectionMenuItems}
                                      requestSearch={requestSearch}
                                      handleChange={handleChange} filter={filterValue}/>
                <TableContainer sx={{maxHeight: "55vh"}}>
                    <Table aria-label="collapsible table">
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            setOrder={setOrder}
                            setOrderBy={setOrderBy}
                            spaceCell={true}
                            headCells={collectionHeadCells}
                        />
                        <TableBody>
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => (
                                    <CollectionTableBody key={row.name} row={row} handleOpen={handleOpen}
                                                         stableSort={stableSort} getComparator={getComparator}/>
                                ))}

                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            <CustomDialog
                onClose={handleClose} closeBtn
                open={open} title={"Edit Collection"}>
                <CreateCollection rowData={rowData} onClose={handleClose}/>
            </CustomDialog>
        </Box>
    );
};

export default CollectionTable;
